<template>
  <div class="cover">
    <img :src="src" alt="">
  </div>
</template>

<script>
export default {
  name: "CoverPicture",
  props: {
    src: {
      type: String,
    }
  }
}
</script>

<style lang="less" scoped>
.cover {
  width: 100%;
  height: 528px;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
