<template>
  <div>
    <CoverPicture :src="data.banner"/>
    <div class="box">


      <div class="left">
        <div class="titleNav cursor">首页>案例>{{ data.title }}！</div>
        <div class="title">{{ data.title }}</div>
        <div class="time"><span class="icon iconjinlingyingcaiwangtubiao74"></span> {{ data.time }}</div>

        <div class="content">
          <div class="contentItem" v-for="(v,i) in data.content" :key="i">
            <div class="t">{{ v.title }}</div>
            <div class="contr" v-html="v.txt">
            </div>
          </div>
        </div>

      </div>
      <div class="right">
        <div class="title">推荐案例</div>
        <div class="boxList">
          <div class="item cursor" @click="recommendedCase('/case?id='+v.id)" v-for="(v,i) in data.list" :key="i">
            <div class="l">
              <img :src="v.logo" alt="">
            </div>
            <div class="r">
              <div>{{ v.title }}</div>
              <div>{{ v.time }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import CoverPicture from "@/components/CoverPicture";
import { Data, pushRouter, scrolTop } from '@/utils/ decorator';

export default {
  components: {
    CoverPicture
  },
  name: "case",
  data() {
    return {
      data: {},
    }
  },
  @Data( 'case' )
      @scrolTop
  created() {
    this.data = this.d.find( r => r.id == this.$route.query.id );
    this.data.list = this.d.filter( r => r.id != this.$route.query.id );
    this.data.content[2].txt = this.data.content[2].txt.split( ',' ).map( m => `<div>${ m }</div>` ).join( '');


  },
  mounted() {
  },
  methods: {
    @pushRouter
    recommendedCase( path ) {
    },
  }
}
</script>

<style lang="less" scoped>


.box {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 112px;
  padding-top: 58px;


  .left {
    width: 50%;

    .titleNav {
      font-family: Microsoft YaHei, serif;
      width: 100%;
      height: 35px;
      font-size: 14px;
      font-weight: 400;
      color: #24439C;
      line-height: 35px;
      padding-left: 10px;
      margin-left: 5px;
      border-radius: 5px;
      background: #f8f8f8;
    }

    .title {
      font-size: 48px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin: 50px 0 30px;
      text-align: left;
    }

    .time {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;

      span {
        font-size: 14px;
        position: relative;
        top: -2px;
      }
    }

    /deep/ .content {
      width: 100%;
      //height: 700px;
      padding-bottom: 44px;
      //border-bottom: 1px solid #F5F5F5;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #666666;
      overflow: hidden;

      .contentItem {

        .t {
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FF5B5B;
          margin-top: 50px;
          margin-bottom: 30px;
        }

        .contr {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #333333;
          text-indent: 35px;
          line-height: 35px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          > div {
            text-align: center;
            border: 1px solid #FF5B5B;
            border-radius: 20px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 300;
            color: #333333;
            text-indent: 0;
            padding: 10px 20px;
            margin: 15px;

          }

        }
      }

      p {
        margin: 30px 0 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .label {
      padding-left: 10px;
      width: 100%;
      margin: 32px 0 31px;
      padding-bottom: 32px;
      border-bottom: 1px solid #F5F5F5;


      .lbox {
        padding-left: 34px;
        padding-top: 16px;
        height: 95px;
        width: 100%;
        background: #BFBFBF;
        border-radius: 5px;

        .t {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-bottom: 13px;

        }

        .a {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #666666;
        }
      }

    }

    .pagination {
      > div {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #3450A0;
        margin-top: 38px;
      }
    }

  }

  .right {
    width: 35%;
    padding-top: 70px;

    .title {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      border-bottom: 1px solid #F5F5F5;
      padding-bottom: 14px;
    }


    .item {
      margin-top: 20px;
      padding-bottom: 18px;
      border-bottom: 1px solid #F5F5F5;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .l {
        width: 27.5%;
        height: 77px;

        img {
          width: 100%;
          height: 100%
        }
      }

      .r {
        width: 65%;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        //height: 57px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

      }

    }

  }

}

</style>







